<template>
	<div class="flex column">
		<div style="max-width: 100%;">
			<h1 class="setting-title">
				Core configuration options: {{ cg.name }}
			</h1>
			<!-- horizontal tabs -->
			<div>
				<q-tabs
					inline-label
					active-color="primary"
					indicator-color="primary"
				>
					<q-route-tab
						v-for="tab in tabs"
						:key="tab.path"
						:name="tab.name"
						:label="tab.name"
						:to="tab.path"
					/>
				</q-tabs>
			</div>
			<router-view></router-view>
		</div>
		<save-button @save="buttonHandler" />
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import TabList from '@/components/utils/tabs/TabList.vue'
import SaveButton from '@/components/datasets/utils/SaveButton.vue'
import AuthAPI from '@/services/api/auth.js'

export default {
	name: 'Settings',
	components: {
		TabList,
		SaveButton,
	},
	data() {
		return {
			splitterModel: 20,
			tabs: [
				{
					path: 'outbound-and-inbound-routing',
					name: 'Routing Content',
				},
				{
					path: 'user-and-maintenance-and-access',
					name: 'User Maintenance And Access',
				},
				{
					path: 'message-sending',
					name: 'Message Sending',
				},
			],
		}
	},
	computed: {
		current_path() {
			return this.$router.resolve({ name: 'Dataset Settings' }).resolved
				.redirectedFrom
		},
		cg() {
			return this.$store.getters.customer_groups.filter(
				(cg) => cg.id == this.$route.params.id
			)[0]
		},
	},
	methods: {
		...mapActions([
			'updateCustomerGroupSettings',
			'updateTimezoneErrorMessage',
			'fetchUser',
		]),
		async buttonHandler() {
			this.updateTimezoneErrorMessage('')
			if (this.$store.getters.has_default_timezone && !this.$store.getters.default_timezone) {
				this.updateTimezoneErrorMessage('You must select a timezone from the list.')
				return
			}
			const response = await this.updateCustomerGroupSettings(
				this.$route.params.id
			)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
			await this.fetchUser()
			// Update the logged user values
			if (typeof this.$route.params.id != 'undefined') {
				const response = await AuthAPI.retrieveUserWithValues('self')
				this.$store.commit('updateUser', response.data)
			}
		},
	},
}
</script>

<style lang="scss">
.outbound-inbound-routing-items {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;
	cursor: default;
}
</style>


