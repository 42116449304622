<template>
	<div class="overflow-x-scroll scrollbar" :id="`scroll-${id}`">
		<div class="tab-list-wrapper" :id="id">
			<div v-for="(tab, index) in tabs" :key="index">
				<tab-link
					:name="tab.name"
					:path="routes[index]"
					:tab_index="index"
					:style="{ width: `${tab_link_width}px` }"
					:active_tab="active_tab"
					@changeTab="changeTab(index)"
				></tab-link>
			</div>
			<div
				class="active-line"
				:style="{
					left: `${active_line_left_pos}px`,
					width: `${tab_link_width}px`,
				}"
			></div>
		</div>
	</div>
</template>

<script>
import TabLink from '@/components/utils/tabs/TabLink'
export default {
	name: 'TabList',
	components: {
		TabLink,
	},
	props: {
		tabs: Array,
		initial_path: String,
	},
	data() {
		return {
			active_tab: 0,
			tab_link_width: null,
			span_class_list: {
				'link-span': true,
				'inline-block': true,
			},
			active_line_left_pos: 0,
		}
	},
	computed: {
		routes() {
			let routes = this.tabs.map((tab) => `${this.initial_path}/${tab.path}`)
			return routes
		},
		id() {
			return `tab-list-wrapper-${Math.random().toString(16).slice(2)}`
		},
	},
	methods: {
		changeTab(index) {
			let wrapper = document.querySelector(`#scroll-${this.id}`)
			let view_coordinate = wrapper.offsetWidth + wrapper.scrollLeft
			let endCoordinate = this.tab_link_width * (index + 1)
			let startCoordinate = endCoordinate - this.tab_link_width
			if (view_coordinate - endCoordinate <= 50) {
				wrapper.scrollLeft =
					endCoordinate - wrapper.offsetWidth + this.tab_link_width / 2
			} else if (
				wrapper.offsetWidth - (view_coordinate - startCoordinate) <=
				50
			) {
				wrapper.scrollLeft -=
					view_coordinate -
					startCoordinate -
					wrapper.offsetWidth +
					this.tab_link_width / 2
			}
			this.active_tab = index
			this.active_line_left_pos = index * this.tab_link_width
		},
	},
	created() {
		this.tabs.filter((tab, index) => {
			if (this.$route.fullPath.split('/').includes(tab.path)) {
				this.active_tab = index
			}
		})

	},
	mounted() {
		let children = document.querySelector(`#${this.id}`).children
		children.forEach((link, index) => {
			if (index === 0) {
				this.tab_link_width = link.offsetWidth
				return
			}
			if (link.offsetWidth > this.tab_link_width) {
				this.tab_link_width = link.offsetWidth
			}
		})
		this.tab_link_width = this.tab_link_width
		requestAnimationFrame(() => {
			this.changeTab(this.active_tab)
		})
	},
}
</script>

<style lang="scss" scoped>
.scrollbar {
	&::-webkit-scrollbar {
		display: none;
	}
}
.tab-list-wrapper {
	@apply text-left;
	@apply flex;
	@apply max-w-full;
	@apply relative;
}
.active-line {
	@apply absolute;
	@apply bottom-0;
	@apply transition-all;
	@apply duration-300;
	@apply ease-in-out;
	height: 2px;
	background-color: var(--ac-highlight-color);
}
</style>
