<template>
	<router-link :to="{ path: path }" class="inline-block" append>
		<span @click="$emit('changeTab', tab_index)" :class="span_class_list">
			{{ name }}
		</span>
	</router-link>
</template>

<script>
export default {
	name: 'TabLink',
	props: {
		path: String,
		tab_index: Number,
		active_tab: Number,
		name: String,
	},
	computed: {
		span_class_list() {
			return {
				'link-span': true,
				'inline-block': true,
				'active-tab-link': this.tab_index === this.active_tab,
			}
		},
	},
	methods: {
		changeTab() {},
	},
}
</script>

<style lang="scss" scoped>
.link-span {
	@apply py-3;
	@apply px-4;
	@apply uppercase;
	@apply font-semibold;
	@apply text-coolGray-800;
	@apply opacity-80;
	@apply text-center;
	@apply hover:bg-coolGray-100;
	@apply transition-all;
	@apply duration-150;
	@apply ease-in-out;
	@apply relative;
	@apply w-full;
	&::before {
		@apply block;
		@apply absolute;
		@apply w-full;
		@apply h-full;
		@apply left-0;
		@apply bottom-0;
		@apply z-0;
		@apply opacity-0;
		@apply transition-all;
		@apply duration-300;
		@apply ease-in-out;
		content: '';
		background-color: var(--ac-highlight-color);
	}
}
.active-tab-link {
	@apply hover:bg-transparent;
	color: var(--ac-highlight-color);
	&::before {
		@apply opacity-10;
	}
}
</style>
